<template>

    <b-overlay :show="loading"
               :variant="variant"
               :opacity="opacity"
               :blur="blur"
               spinner-variant="primary"
               spinner-type="grow"
               spinner-large
               class="loaderPreview"
               rounded="sm">
        <section>
            <b-row v-if="(meta.breadcrumb || meta.pageTitle) && dataLoaded"
                   class="content-header">

                <b-col class="content-header-left mb-2"
                       cols="12"
                       md="6">
                    <b-row class="breadcrumbs-top">
                        <b-col cols="12">
                            <h2 class="content-header-title float-left pr-1 mb-0">
                                {{ meta.pageTitle }}
                            </h2>
                            <div class="breadcrumb-wrapper">
                                <b-breadcrumb>
                                    <b-breadcrumb-item to="/">
                                        <feather-icon icon="HomeIcon"
                                                      size="16"
                                                      class="align-text-top" />
                                    </b-breadcrumb-item>
                                    <b-breadcrumb-item v-for="item in meta.breadcrumb"
                                                       :key="item.text"
                                                       :active="item.active"
                                                       :to="item.to">
                                        {{ item.text }}
                                    </b-breadcrumb-item>
                                </b-breadcrumb>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>

                <!-- Content Right -->
                <b-col class="content-header-right text-md-right d-md-block d-none mb-1"
                       md="6"
                       cols="12">

                </b-col>
            </b-row>
            <div class="post">

                <div v-if="error" class="error">
                    {{ error }}
                </div>
            </div>


            <b-tabs vertical
                    content-class="col-12 col-md-9 mt-1 mt-md-0"
                    pills
                    nav-wrapper-class="col-md-3 col-12"
                    nav-class="nav-left"
                    v-on:activate-tab="tabActivated"
                    v-if="dataLoaded">
                <b-tab :active="activeTab === 'PayPal'" title="PayPal">
                    <b-card>
                        <b-row>
                            <b-col cols="6">
                                <h1> PayPal Accounts</h1>
                            </b-col>
                            <b-col cols="6" class="text-right">

                                <b-button @click="addEditPayPal" data-id="0" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          type="button"
                                          variant="outline-success">
                                    <feather-icon icon="PlusIcon" size="18" />
                                    Add New Account
                                </b-button>

                            </b-col>
                        </b-row>
                    </b-card>
                    <b-row class="match-height">
                        <b-col cols="12">
                            <b-card class="cardMobile">

                                <b-table striped hover caption-top fixed bordered
                                         v-if="payPalAccoutns"
                                         :items="payPalAccoutns"
                                         :fields="payPalTablefields"
                                         :search-options="{enabled: true,externalQuery: payPalSearchTerm }"
                                         :pagination-options="{enabled: true,perPage:payPalPageLength}"
                                         show-empty>
                                    <template #empty="scope">
                                        Record doesn't exist
                                    </template>

                                    <template v-slot:cell(Id)="data">
                                        <b-button :data-id="data.item.Id"
                                                  @click="addEditPayPal"
                                                  title="Edit"
                                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                  v-b-tooltip.hover.v-info
                                                  variant="flat-info"
                                                  class="btn-icon">
                                            <feather-icon icon="EditIcon" />
                                        </b-button>

                                        <!--<b-button :data-id="data.item.Id"
                                                  @click="removePayPal"
                                                  title="Delete"
                                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                  v-b-tooltip.hover.v-danger
                                                  variant="flat-danger"
                                                  class="btn-icon">
                                            <feather-icon icon="ArchiveIcon" />
                                        </b-button>-->
                                    </template>
                                </b-table>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-tab>

                <b-tab title="Stripe" :active="activeTab === 'Stripe'">

                    <b-card>
                        <b-row>
                            <b-col cols="6">
                                <h1>Stripe Accounts</h1>
                            </b-col>
                            <b-col cols="6" class="text-right">
                                <b-button @click="addEditStripe" data-id="0" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          type="button"
                                          variant="outline-success">
                                    <feather-icon icon="PlusIcon" size="18" />
                                    Add New Account
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <b-row class="match-height">

                        <b-col cols="12">
                            <b-card class="cardMobile">

                                <b-table striped hover caption-top fixed bordered
                                         v-if="stripeAccoutns"
                                         :items="stripeAccoutns"
                                         :fields="stripeTablefields"
                                         :search-options="{enabled: true,externalQuery: stripeSearchTerm }"
                                         :pagination-options="{enabled: true,perPage:stripePageLength}"
                                         show-empty>
                                    <template #empty="scope">
                                        Record doesn't exist
                                    </template>
                                    <template v-slot:cell(Id)="data">
                                        <b-button :data-id="data.item.Id"
                                                  @click="addEditStripe"
                                                  title="Edit"
                                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                  v-b-tooltip.hover.v-info
                                                  variant="flat-info"
                                                  class="btn-icon">
                                            <feather-icon icon="EditIcon" />
                                        </b-button>

                                        <!--<b-button :data-id="data.item.Id"
                                                  @click="removeStripe"
                                                  title="Delete"
                                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                  v-b-tooltip.hover.v-danger
                                                  variant="flat-danger"
                                                  class="btn-icon">
                                            <feather-icon icon="ArchiveIcon" />
                                        </b-button>-->
                                    </template>
                                </b-table>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-tab>

                <b-tab title="Authorize.Net">
                    <b-card>
                        <b-row>
                            <b-col cols="6">
                                <h1>Authorize.Net Accounts</h1>
                            </b-col>
                            <b-col cols="6" class="text-right">
                                <b-button @click="addEditAuthorizeNet" data-id="0" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          type="button"
                                          variant="outline-success">
                                    <feather-icon icon="PlusIcon" size="18" />
                                    Add New Account
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>

                    <b-row class="match-height">

                        <b-col cols="12">
                            <b-card class="cardMobile">

                                <b-table striped hover caption-top fixed bordered
                                         v-if="authorizeAccoutns"
                                         :items="authorizeAccoutns"
                                         :fields="authorizeTablefields"
                                         :search-options="{enabled: true,externalQuery: authorizeSearchTerm }"
                                         :pagination-options="{enabled: true,perPage:authorizePageLength}"
                                         show-empty>
                                    <template #empty="scope">
                                        Record doesn't exist
                                    </template>

                                    <template v-slot:cell(Id)="data">
                                        <b-button :data-id="data.item.Id"
                                                  @click="addEditAuthorizeNet"
                                                  title="Edit"
                                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                  v-b-tooltip.hover.v-info
                                                  variant="flat-info"
                                                  class="btn-icon">
                                            <feather-icon icon="EditIcon" />
                                        </b-button>

                                        <!--<b-button :data-id="data.item.Id"
                                                  @click="removeAuthorizeNet"
                                                  title="Delete"
                                                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                  v-b-tooltip.hover.v-danger
                                                  variant="flat-danger"
                                                  class="btn-icon">
                                            <feather-icon icon="ArchiveIcon" />
                                        </b-button>-->
                                    </template>
                                </b-table>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-tab>

                <!--<b-tab title="Braintree">
                    <b-card>
                        <b-row>
                            <b-col cols="6">
                                <h1>Braintree Accounts</h1>
                            </b-col>
                            <b-col cols="6" class="text-right">
                                <b-button @click="addEditBrainTree" data-id="0" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                          type="button"
                                          variant="success">
                                    Add New Braintree Account
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>
                    <b-row class="match-height">

                        <b-col cols="12">
                            <b-card>

                                <b-table striped hover caption-top fixed bordered
                                         v-if="brainTreeAccoutns"
                                         :items="brainTreeAccoutns"
                                         class="text-center"
                                         :fields="brainTreeTablefields"
                                         :search-options="{enabled: true,externalQuery: brainTreeSearchTerm }"
                                         :pagination-options="{enabled: true,perPage:brainTreePageLength}">

                                    <template v-slot:cell(Id)="data">
                                        <b-button :data-id="data.item.Id" @click="addEditBrainTree" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                  variant="info"
                                                  class="btn-icon">
                                            <feather-icon icon="EditIcon" />
                                        </b-button>

                                        <b-button :data-id="data.item.Id" @click="removeBrainTree" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                  variant="danger"
                                                  class="btn-icon">
                                            <feather-icon icon="ArchiveIcon" />
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-card>
                        </b-col>
                    </b-row>
                </b-tab>-->
            </b-tabs>

            <b-modal id="payPalAccountModal"
                     ref="modal"
                     size="lg"
                     title="PayPal Account"
                     @hidden="resetModal"
                     centered
                     ok-title="Submit">
                <validation-observer ref="payPalRules">
                    <form ref="form" v-if="payPalAccoutnDetails">
                        <b-row>
                            <b-col lg="9">
                                <b-form-group>

                                    <label for="AccountName">Account<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="Account Name">
                                        <b-form-input id="AccountName"
                                                      name="AccountName"
                                                      v-model="payPalAccoutnDetails.AccountName"
                                                      placeholder="Please Enter Account Name"
                                                      :state="errors.length > 0 ? false:null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="3">
                                <b-form-group>
                                    <label for="IsActive">Status<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="IsActive">
                                    <select class="form-control"
                                            name="IsActive"
                                            id="IsActive"
                                            v-model="payPalAccoutnDetails.IsActive">
                                        <option value="true">Active</option>
                                        <option value="false">InActive</option>
                                    </select>
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!--<b-col lg="6">
                                <b-form-group>
                                    <label>Is Sandbox?</label>
                                    <div class="demo-inline-spacing">
                                        <b-form-radio id="IsSandBoxTrue"
                                                      value="true"
                                                      @change="payPalAccoutnDetails.IsSandBox=true"
                                                      v-model="payPalAccoutnDetails.IsSandBox">
                                            Yes
                                        </b-form-radio>
                                        <br>
                                        <b-form-radio id="IsSandBoxFalse"
                                                      value="false"
                                                      @change="payPalAccoutnDetails.IsSandBox=false"
                                                      v-model="payPalAccoutnDetails.IsSandBox">
                                            No
                                        </b-form-radio>
                                    </div>
                                </b-form-group>
                            </b-col>-->
                        </b-row>
                        <b-row>
                            <!--v-if="payPalAccoutnDetails.IsSandBox==true || payPalAccoutnDetails.IsSandBox=='true'"-->
                            <b-col lg="12">
                                <b-form-group>
                                    <label>Sandbox Client ID<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="Sandbox Client ID">
                                        <b-form-input id="SandboxClientID"
                                                      name="SandboxClientID"
                                                      v-model="payPalAccoutnDetails.SandboxClientID"
                                                      placeholder="Please Enter Sandbox Client ID"
                                                      :state="errors.length > 0 ? false:null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <label>Sandbox Client Secret<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="Sandbox Client Secret">
                                        <b-form-input id="SandboxClientSecret"
                                                      name="SandboxClientSecret"
                                                      v-model="payPalAccoutnDetails.SandboxClientSecret"
                                                      placeholder="Please Enter Sandbox Client Secret"
                                                      :state="errors.length > 0 ? false:null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>


                        </b-row>
                        <b-row>
                            <!-- v-if="payPalAccoutnDetails.IsSandBox==false || payPalAccoutnDetails.IsSandBox=='false'"-->
                            <b-col lg="12">
                                <b-form-group>
                                    <label>Live Client ID<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="Live Client ID">
                                        <b-form-input id="LiveClientID"
                                                      name="LiveClientID"
                                                      v-model="payPalAccoutnDetails.LiveClientID"
                                                      placeholder="Please Enter Live Client ID"
                                                      :state="errors.length > 0 ? false:null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <label>Live Client Secret<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="Live Client Secret">
                                        <b-form-input id="LiveClientSecret"
                                                      name="LiveClientSecret"
                                                      v-model="payPalAccoutnDetails.LiveClientSecret"
                                                      placeholder="Please Enter Live Client Secret"
                                                      :state="errors.length > 0 ? false:null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </form>
                </validation-observer>

                <slot></slot>
                <template #modal-footer="{ cancel, ok }">
                    <b-button @click="closePayPalModal"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="secondary"
                              type="button">
                        <feather-icon icon="XCircleIcon" size="18" />
                        Cancel
                    </b-button>

                    <b-button @click="savePayPalAccount"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              type="button">
                        <feather-icon icon="SaveIcon" size="18" />
                        Save
                    </b-button>

                </template>
            </b-modal>

            <b-modal id="stripeAccountModal"
                     ref="modal"
                     size="lg"
                     title="Stripe Account"
                     @hidden="resetModal"
                     centered
                     ok-title="Submit">
                <validation-observer ref="stripeRules">
                    <form ref="form" v-if="stripeAccountDetails">
                        <b-row>
                            <b-col lg="9">
                                <b-form-group>
                                    <label>Email<span class="required"> *</span> <span v-if="stripeAccountDetails && stripeAccountDetails.Id>0"></span></label>
                                    <validation-provider ref="emailValidator" #default="{ errors }"
                                                         rules="required|email"
                                                         name="Email">
                                        <b-form-input id="Email"
                                                      name="Email"
                                                      v-model="stripeAccountDetails.Email"
                                                      placeholder="Please Enter Account Email"
                                                      :state="errors.length > 0 ? false:null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="3">
                                <b-form-group>
                                    <label for="IsActive">Status<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="IsActive">
                                        <select class="form-control"
                                                name="IsActive"
                                                id="IsActive"
                                                v-model="stripeAccountDetails.IsActive">
                                            <option value="true">Active</option>
                                            <option value="false">InActive</option>
                                        </select>
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <label>Sandbox Publishable Key<span class="required"> *</span></label>
                                    <b-button @click="validateEmailAndConnect('Test')"
                                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                              variant="outline-primary"
                                              type="button"
                                              class="float-right">
                                        <feather-icon icon="SaveIcon" size="18" />
                                        Connect Test Mode
                                    </b-button>
                                    <validation-provider #default="{errors}"
                                                         rules="required"
                                                         name="Test Publishable Key">
                                        <b-form-input id="StripePublishableKey"
                                                      name="StripePublishableKey"
                                                      placeholder="Please Enter Test Publishable Key"
                                                      :state="errors.length > 0 ? false:null"
                                                      v-model="stripeAccountDetails.StripePublishableKey" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>

                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <label>Sandbox Secret Key<span class="required"> *</span></label>
                                    <validation-provider #default="{errors}"
                                                         rules="required"
                                                         name="Test Secret Key">

                                        <b-form-input id="StripeSecretKey"
                                                      name="StripeSecretKey"
                                                      v-model="stripeAccountDetails.StripeSecretKey"
                                                      placeholder="Please Enter Test Secret Key"
                                                      :state="errors.length > 0 ? false:null" />

                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>

                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <label>Live Publishable Key<span class="required"> *</span></label>

                                    <b-button @click="validateEmailAndConnect('Live')"
                                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                              variant="outline-info"
                                              type="button"
                                              class="float-right">
                                        <feather-icon icon="SaveIcon" size="18" />
                                        Connect Live Mode
                                    </b-button>

                                    <validation-provider #default="{errors}"
                                                         rules="required"
                                                         name="Live Publishable Key">
                                        <b-form-input id="StripeLivePublishableKey"
                                                      name="StripeLivePublishableKey"
                                                      v-model="stripeAccountDetails.StripeLivePublishableKey"
                                                      placeholder="Please Enter Live Publishable Key"
                                                      :state="errors.length > 0 ? false:null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>

                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <b-form-group>
                                    <label>Live Secret Key<span class="required"> *</span></label>
                                    <validation-provider #default="{errors}"
                                                         rules="required"
                                                         name="Live Secret Key">
                                        <b-form-input id="StripeLiveSecretKey"
                                                      name="StripeLiveSecretKey"
                                                      v-model="stripeAccountDetails.StripeLiveSecretKey"
                                                      placeholder="Please Enter Live Secret Key"
                                                      :state="errors.length > 0 ? false:null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>

                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                        </b-row>


                    </form>
                </validation-observer>

                <slot></slot>
                <template #modal-footer="{ cancel, ok }">
                    <b-button @click="closeStripeModal"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="secondary"
                              type="button">
                        <feather-icon icon="XCircleIcon" size="18" />
                        Cancel
                    </b-button>

                    <b-button @click="SaveStripeAccount"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              type="button">
                        <feather-icon icon="SaveIcon" size="18" />
                        Save
                    </b-button>


                </template>
            </b-modal>

            <b-modal id="AuthorizeNetModal"
                     ref="modal"
                     size="lg"
                     title="Authorize Net Account"
                     @hidden="resetModal"
                     centered
                     ok-title="Submit">
                <validation-observer ref="authorizeNetRules">
                    <form ref="form" v-if="authorizeNetAccountDetails">
                        <b-row>
                            <b-col lg="9">
                                <b-form-group>
                                    <label>Account Name<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="Account Name">
                                        <b-form-input id="AccountName"
                                                      name="AccountName"
                                                      v-model="authorizeNetAccountDetails.AccountName"
                                                      placeholder="Please Enter Account Name"
                                                      :state="errors.length > 0 ? false:null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="3">
                                <b-form-group>
                                    <label for="IsActive">Status<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="IsActive">
                                        <select class="form-control"
                                                name="IsActive"
                                                id="IsActive"
                                                v-model="authorizeNetAccountDetails.IsActive">
                                            <option value="true">Active</option>
                                            <option value="false">InActive</option>
                                        </select>
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="6">
                                <b-form-group>
                                    <label>Sandbox API Login ID<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="Sandbox API Login ID">
                                        <b-form-input id="ApiLoginId"
                                                      name="ApiLoginId"
                                                      v-model="authorizeNetAccountDetails.ApiLoginId"
                                                      placeholder="Please Enter Sandbox API Login ID"
                                                      :state="errors.length > 0 ? false:null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="6">
                                <b-form-group>
                                    <label>Sandbox Transaction Key<span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="Transaction Key">
                                        <b-form-input id="TransactionKey"
                                                      name="TransactionKey"
                                                      v-model="authorizeNetAccountDetails.AuthorizeNetTransactionKey"
                                                      placeholder="Please Enter Stripe Secret Key"
                                                      :state="errors.length > 0 ? false:null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="6">
                                <b-form-group>
                                    <label>Live API Login ID<span class="required"> *</span></label>
                                    <validation-provider #default="{errors}"
                                                         rules="required"
                                                         name="Live API Login ID">
                                        <b-form-input id="LiveApiLoginId"
                                                      name="LiveApiLoginId"
                                                      v-model="authorizeNetAccountDetails.LiveApiLoginId"
                                                      placeholder="Please Enter Live API Login ID"
                                                      :state="errors.length>0 ? false:null" />
                                        <div class="popup-validation"><small class="text-danger">{{errors[0]}}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="6">
                                <b-form-group>
                                    <label>Live Transaction Key<span class="required"> *</span></label>
                                    <validation-provider #default="{errors}"
                                                         rules="required"
                                                         name="Live Transaction Key">
                                        <b-form-input id="LiveAuthorizeNetTransactionKey"
                                                      name="LiveAuthorizeNetTransactionKey"
                                                      v-model="authorizeNetAccountDetails.LiveAuthorizeNetTransactionKey"
                                                      placeholder="Please Enter Live Transaction Key"
                                                      :state="errors.length>0 ? false:null" />
                                        <div class="popup-validation"><small class="text-danger">{{errors[0]}}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <label>Get SandBox Credentials </label>
                                <b-input-group>

                                    <b-form-input id="ApiLoginId"
                                                  name="ApiLoginId"
                                                  :value="this.LoginIDURL"
                                                  readonly
                                                  placeholder="Please Enter Stripe Secret Key" />

                                    <b-input-group-append>
                                        <b-button variant="outline-primary">
                                            <b-link :href="this.LoginIDURL"
                                                    target="_blank">
                                                <feather-icon icon="ExternalLinkIcon" />
                                            </b-link>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>


                            </b-col>

                            <b-col lg="12">
                                <label>Get Live Credentials</label>
                                <b-input-group>

                                    <b-form-input id="TransactionKey"
                                                  name="TransactionKey"
                                                  :value="this.LoginIDURL"
                                                  readonly
                                                  placeholder="" />

                                    <b-input-group-append>
                                        <b-button variant="outline-primary">
                                            <b-link :href="this.LoginIDURL"
                                                    target="_blank">
                                                <feather-icon icon="ExternalLinkIcon" />
                                            </b-link>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                        </b-row>


                    </form>
                </validation-observer>

                <slot></slot>
                <template #modal-footer="{ cancel, ok }">
                    <b-button @click="closeAuthorizeModal"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="secondary"
                              type="button">
                        <feather-icon icon="XCircleIcon" size="18" />
                        Cancel
                    </b-button>

                    <b-button @click="saveAuthorizeNetAccount"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              variant="primary"
                              type="button">
                        <feather-icon icon="SaveIcon" size="18" />
                        Save
                    </b-button>

                </template>
            </b-modal>

            <!--<b-modal id="BrainTreeModal"
                 ref="modal"
                 size="lg"
                 title="Brain Tree Account"
                 @hidden="resetModal"
                 centered
                 ok-title="Submit">
            <validation-observer ref="brainTreeRules">
                <form ref="form" v-if="brainTreeAccountDetails">
                    <b-row>

                        <b-col lg="12">
                            <b-form-group>
                                <label>Account Name <span class="required"> *</span></label>
                                <validation-provider #default="{ errors }"
                                                     rules="required"
                                                     name="Account Name">
                                    <b-form-input id="AccountName"
                                                  name="AccountName"
                                                  v-model="brainTreeAccountDetails.AccountName"
                                                  placeholder="Give Account a Name..."
                                                  :state="errors.length > 0 ? false:null" />
                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col lg="6">
                            <b-form-group>
                                <label>Merchant Id <span class="required"> *</span></label>
                                <validation-provider #default="{ errors }"
                                                     rules="required"
                                                     name="Merchant Id">
                                    <b-form-input id="merchantId"
                                                  name="merchantId"
                                                  v-model="brainTreeAccountDetails.MerchantId"
                                                  placeholder="Merchant Id"
                                                  :state="errors.length > 0 ? false:null" />
                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col lg="6">
                            <b-form-group>
                                <label>Merchant Account Id</label>
                                <b-form-input id="MerchantAccountId"
                                              name="MerchantAccountId"
                                              v-model="brainTreeAccountDetails.MerchantAccountId"
                                              placeholder="Merchant Id" />
                            </b-form-group>
                        </b-col>-->
            <!--<b-col lg="4">
                <b-form-group>
                    <label>Plan Id</label>
                    <b-form-input id="PlanId"
                                  name="PlanId"
                                  v-model="brainTreeAccountDetails.PlanId"
                                  placeholder="Plan Id" />
                </b-form-group>
            </b-col>-->
            <!--<b-col lg="6">
                                <b-form-group>
                                    <label>Private Key <span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="Private Key">
                                        <b-form-input id="PrivateKey"
                                                      name="PrivateKey"
                                                      v-model="brainTreeAccountDetails.PrivateKey"
                                                      placeholder="Private Key"
                                                      :state="errors.length > 0 ? false:null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col lg="6">
                                <b-form-group>
                                    <label>Public Key <span class="required"> *</span></label>
                                    <validation-provider #default="{ errors }"
                                                         rules="required"
                                                         name="Public Key">
                                        <b-form-input id="PublicKey"
                                                      name="PublicKey"
                                                      v-model="brainTreeAccountDetails.PublicKey"
                                                      placeholder="Public Key"
                                                      :state="errors.length > 0 ? false:null" />
                                        <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col lg="12">
                                <label>Get Credentials </label>
                                <b-input-group>

                                    <b-form-input id="Credential"
                                                  name="Credential"
                                                  :value="this.BrainTreeCredentialsUrl"
                                                  readonly
                                                  placeholder="" />

                                    <b-input-group-append>
                                        <b-button variant="outline-primary">
                                            <b-link :href="this.BrainTreeCredentialsUrl"
                                                    target="_blank">
                                                <feather-icon icon="ExternalLinkIcon" />
                                            </b-link>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>


                            </b-col>


                        </b-row>


                    </form>
                </validation-observer>

                <slot></slot>
                <template #modal-footer="{ cancel, ok }">
                    <b-button @click="saveBrainTreeAccount"
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="outline-primary"
                              type="button">
                        Save
                    </b-button>

                </template>
            </b-modal>-->

        </section>
    </b-overlay>

</template>

<script>
    import BCardCode from '@core/components/b-card-code'



    import {
        BCard, BCardHeader, BCardBody, BCardImg, BRow, BCol, BCardText, BCardTitle, BCardSubTitle, BLink, BButton, BModal, VBModal,
        BFormInput, BForm, BFormRow, BFormValidFeedback, BFormInvalidFeedback, BFormGroup, BFormRadio,
        BTabs, BTab, BTable, BOverlay, BInputGroupAppend, BInputGroup,
        BBreadcrumb, BBreadcrumbItem,
        VBTooltip,
    } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
    } from '@validations'
    import { $themeConfig } from '@themeConfig'
    import { VueGoodTable } from 'vue-good-table'
    import Ripple from 'vue-ripple-directive'
    import jwt from '@/auth/jwt/useJwt'
    import axis from 'axios'

    import { extend } from 'vee-validate';
    extend('required', {
        message: 'This Field is required',
    });
    extend('email', {
        message: 'Invalid email address',
    });

    export default {
        metaInfo: {
            title: 'Landingly | Payment Methods'
        },
        components: {
            BCardCode,
            BCardText,
            BCard,
            BCardHeader,
            BRow,
            BCol,
            BCardBody,
            BCardImg,
            BLink,
            BModal,
            VBModal,
            BButton,
            BFormInput,
            BForm,
            BFormRow,
            BFormValidFeedback,
            BFormInvalidFeedback,
            BFormGroup,
            BFormRadio,
            BTabs,
            BTab,
            BTable,
            ValidationProvider,
            ValidationObserver,
            BOverlay,
            BCardTitle,
            BCardSubTitle,
            VueGoodTable,
            BInputGroupAppend,
            BInputGroup,
            BBreadcrumb, BBreadcrumbItem,

        },
        data() {
            return {
                loading: false,
                variant: "light",
                opacity: 0.85,
                blur: '2px',
                activeTab: "PayPal",

                meta: {
                    pageTitle: 'Payment Methods',
                    breadcrumb: [
                        {
                            text: 'Payment Methods',
                            active: true
                        },
                    ],
                },

                error: null,
                WebAPIURL: $themeConfig.app.apiURL,

                dataLoaded: false,
                fixed: true,

                stripeMode: null,
                stripeAccountDetails: null,
                stripeLiveAccountDetails: null,
                stripeAccoutns: null,

                stripeTablefields: [
                    {
                        key: 'Email',
                        label: 'Account',
                        sortable: true
                    },
                    {
                        key: 'IsActive',
                        label: 'Status',
                        sortable: true,
                        formatter: (value, key, item) => {
                            if (item.IsActive == true) {
                                return 'Active';
                            } else {
                                return 'InActive';
                            }
                        },
                    },
                    //{
                    //    key: 'ClientId',
                    //    label: 'Client Id',
                    //    sortable: true
                    //},
                    //{
                    //    key: 'StripeUserId',
                    //    label: 'Stripe User Id',
                    //    sortable: true
                    //},
                    //{
                    //    key: 'StripePublishableKey',
                    //    label: 'Stripe Publishable Key',
                    //    sortable: true
                    //},
                    {
                        key: 'Id',
                        label: 'Actions',
                        sortable: false,
                        thStyle: { width: "140px" },
                    }],
                stripePageLength: 10,
                stripeSearchTerm: '',



                payPalAccoutnDetails: null,
                payPalAccoutns: null,
                payPalTablefields: [{
                    key: 'AccountName',
                    label: 'Account',
                    sortable: true
                },
                {
                    key: 'IsActive',
                    label: 'Status',
                    sortable: true,
                    formatter: (value, key, item) => {
                        if (item.IsActive == true) {
                            return 'Active';
                        } else {
                            return 'InActive';
                        }
                    },
                },
                //{
                //    key: 'SandboxClientID',
                //    label: 'Sandbox Client ID',
                //    sortable: true
                //},
                //{
                //    key: 'SandboxClientSecret',
                //    label: 'Sandbox Client Secret',
                //    sortable: true
                //},
                //{
                //    key: 'LiveClientID',
                //    label: 'Live Client ID',
                //    sortable: true
                //},
                //{
                //    key: 'LiveClientSecret',
                //    label: 'Live Client Secret',
                //    sortable: true
                //},
                {
                    key: 'Id',
                    label: 'Actions',
                    sortable: false,
                    thStyle: { width: "140px" },
                }],
                payPalPageLength: 10,
                payPalSearchTerm: '',

                authorizeNetAccountDetails: null,
                authorizeAccoutns: null,
                authorizeTablefields: [

                    {
                        key: 'AccountName',
                        label: 'Account',
                        sortable: true
                    },
                    {
                        key: 'IsActive',
                        label: 'Status',
                        sortable: true,
                        formatter: (value, key, item) => {
                            if (item.IsActive == true) {
                                return 'Active';
                            } else {
                                return 'InActive';
                            }
                        },
                    },
                    //{
                    //    key: 'ApiLoginId',
                    //    label: 'Api Login Id',
                    //    sortable: true
                    //},
                    //{
                    //    key: 'AuthorizeNetTransactionKey',
                    //    label: 'Transaction Key',
                    //    sortable: true
                    //},
                    {
                        key: 'Id',
                        label: 'Actions',
                        sortable: false,
                        thStyle: { width: "140px" },
                    }],
                authorizePageLength: 10,
                authorizeSearchTerm: '',
                brainTreeAccountDetails: null,
                brainTreeAccoutns: null,
                brainTreeTablefields: [
                    {
                        key: 'AccountName',
                        label: 'Accpount Name',
                        sortable: true
                    },
                    {
                        key: 'MerchantId',
                        label: 'Merchant Id',
                        sortable: true
                    },
                    {
                        key: 'MerchantAccountId',
                        label: 'Merchant Account Id',
                        sortable: true
                    },
                    {
                        key: 'PlanId',
                        label: 'Plan Id',
                        sortable: true
                    },
                    {
                        key: 'PrivateKey',
                        label: 'Private Key',
                        sortable: true
                    },
                    {
                        key: 'PublicKey',
                        label: 'Public Key',
                        sortable: true
                    },
                    {
                        key: 'Id',
                        label: 'Actions',
                        sortable: false
                    }
                ],
                brainTreePageLength: 10,
                brainTreeSearchTerm: '',

                stripescope: null,
                stripeCode: null,
                stripeState: null,
                stripeAccountId: null,
                LoginIDURL: "https://sandbox.authorize.net/ui/themes/sandbox/User/TransactionKey.aspx",
                BrainTreeCredentialsUrl: "https://sandbox.braintreegateway.com/merchants/rrt9tcq9vkgy53qq/users/5tgmg975nycbdbsq/api_keys/hcb4b3jbjknsz74q"

            }
        },
        directives: {
            'b-modal': VBModal,
            'b-tooltip': VBTooltip,
            Ripple,
        },
        created() {
            var subDomain = jwt.getSubDomain();
            //if (subDomain == null || subDomain == "" || subDomain == 'undefined') {
            //    //this.$router.replace('/setupuseraccount')
            //    this.$router.replace('/login')
            //}
            //else {
            if (this.$route.name == 'verifystripe-account') {
                this.stripescope = this.$route.query.scope;
                this.stripeCode = this.$route.query.code;
                this.stripeState = this.$route.query.state;
                /*this.stripeAccountId = this.$route.query.redirect_uri*/

                this.verifyStripeAccount()
            }
            else {
                this.fetchPayPalAccounts()
            }
            //}
        },
        beforeCreate() {
            
            //else {
            //    if (this.$route.name == 'verifystripe-account') {
            //        this.stripescope = this.$route.query.scope;
            //        this.stripeCode = this.$route.query.code;
            //        this.stripeState = this.$route.query.state;
            //        /*this.stripeAccountId = this.$route.query.redirect_uri*/

            //        this.verifyStripeAccount()
            //    }
            //    else {
            //        this.fetchPayPalAccounts()
            //    }
            //}
        },
        watch: {
            '$route': 'fetchPayPalAccounts'
        },

        methods: {

            resetModal() {

            },

            handleOk(bvModalEvt) {
                bvModalEvt.preventDefault();
                this.savePayPalAccount()
            },
            checkFormValidity() {
                const valid = this.$refs.form.checkValidity()
                return valid
            },


            tabActivated(newTabIndex, oldTabIndex, event) {

                if (newTabIndex == 0) {
                    this.fetchPayPalAccounts();
                }
                else if (newTabIndex == 1) {
                    this.fetchStripeAccounts();
                }
                else if (newTabIndex == 2) {
                    this.fetchAuthorizeNetAccounts();
                }
                else if (newTabIndex == 3) {
                    this.fetchBrainTreeAccounts();
                }
            },

            fetchPayPalAccounts: async function () {

                this.error = null
                var self = this;
                this.loading = true

                const access_token = await jwt.getToken();

                axis.get('' + this.WebAPIURL + '/api/UserProfile/GetPayPalAccount',
                    {
                        headers: {
                            'Authorization': 'bearer ' + access_token,
                            'Content-Type': 'x-www-form-urlencode'
                        }
                    }
                )
                    .then(response => {
                        //console.log("PayPal response.data:: " + response.data);
                        self.payPalAccoutns = response.data;
                        self.loading = false;
                        self.dataLoaded = true;
                    })
                    .catch(error => {
                        self.dataLoaded = true;
                        this.errorMessage = error.message;
                        // console.error("There was an error!", error);
                    });
            },

            addEditPayPal: async function (e) {

                const payPalId = e.currentTarget.getAttribute('data-id');

                this.error = null
                var self = this;
                this.loading = true
                const access_token = await jwt.getToken();

                const params = new URLSearchParams();
                params.append('Id', payPalId);


                axis.get('' + this.WebAPIURL + '/api/UserProfile/GetPayPalDetails',
                    {
                        params: params,
                        headers: {
                            'Authorization': 'bearer ' + access_token,
                            'Content-Type': 'x-www-form-urlencode'
                        }
                    }
                )
                    .then(response => {
                        self.payPalAccoutnDetails = response.data;
                        self.$nextTick(() => {
                            self.$bvModal.show('payPalAccountModal')
                        })
                        self.loading = false
                    })
                    .catch(error => {
                        this.errorMessage = error.message;
                        // console.error("There was an error!", error);
                    });
            },

            savePayPalAccount1: async function () {
                if (!this.checkFormValidity()) {
                    return
                }
            },

            closePayPalModal() {
                this.$bvModal.hide("payPalAccountModal");
            },

            savePayPalAccount: async function () {

                this.error = null
                var self = this;
                const access_token = await jwt.getToken();

                this.$refs.payPalRules.validate().then((success) => {
                    if (success) {

                        self.loading = true
                        var data = {
                            'Id': this.payPalAccoutnDetails.Id,
                            'AccountName': this.payPalAccoutnDetails.AccountName,
                            'IsActive': this.payPalAccoutnDetails.IsActive,
                            //'IsSandBox': this.payPalAccoutnDetails.IsSandBox,
                            'SandboxClientID': this.payPalAccoutnDetails.SandboxClientID,
                            'SandboxClientSecret': this.payPalAccoutnDetails.SandboxClientSecret,
                            'LiveClientID': this.payPalAccoutnDetails.LiveClientID,
                            'LiveClientSecret': this.payPalAccoutnDetails.LiveClientSecret,
                        };

                        axis.post('' + this.WebAPIURL + '/api/UserProfile/AddEditPayPal',
                            data,
                            {
                                headers: {
                                    'Authorization': 'bearer ' + access_token,
                                    'Content-Type': 'application/json'
                                }
                            }
                        )
                            .then(response => {
                                if (response.data === true) {
                                    self.$nextTick(() => {
                                        self.$bvModal.hide('payPalAccountModal')
                                    })
                                    if (this.payPalAccoutnDetails.Id > 0) {
                                        self.$swal(
                                            "Success",
                                            "",
                                            "success"
                                        );
                                    }
                                    else {
                                        self.$swal(
                                            "Success",
                                            "",
                                            "success"
                                        );
                                    }

                                    self.loading = false;
                                    self.fetchPayPalAccounts();

                                }
                                else {
                                    self.loading = false;
                                    self.$swal(
                                        "Error",
                                        "Your PayPal Account could not be verified. Try again",
                                        "error"
                                    );

                                }
                            })
                            .catch(error => {
                                this.errorMessage = error.message;
                                // console.error("There was an error!", error);
                            });
                    }
                });
            },

            removePayPal: async function (e) {
                const payPalId = e.currentTarget.getAttribute('data-id');
                const access_token = await jwt.getToken();
                this.error = null;
                var self = this;
                this.$swal({
                    title: "Delete PayPal Account",
                    text: "Are you sure?",
                    /*                    icon: "warning",*/
                    showCancelButton: true,
                    confirmButtonText: "Delete",
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        self.loading = true

                        let formData = new FormData();

                        formData.append('Id', payPalId);

                        const params = new URLSearchParams();
                        params.append('Id', payPalId);

                        axis.post('' + self.WebAPIURL + '/api/UserProfile/DeletePayPalAccount',
                            formData,
                            {
                                headers: {
                                    'Authorization': 'bearer ' + access_token,
                                    'Content-Type': 'application/json'
                                }
                            }
                        )
                            .then(response => {
                                self.loading = false
                                //if (response.data) {
                                //    this.$swal(
                                //        "Success",
                                //        "PayPal Account is deleted successfully",
                                //        "success"
                                //    );
                                //}
                                //else {
                                //    this.$swal(
                                //        "Error",
                                //        "PayPal Account cannot be deleted",
                                //        "error"
                                //    );
                                //}
                                self.fetchPayPalAccounts();
                                //self.payPalAccoutns = response.data;
                            })
                            .catch(error => {
                                this.errorMessage = error.message;
                                // console.error("There was an error!", error);
                            });
                    }
                });
            },

            fetchStripeAccounts: async function () {
                this.error = null
                var self = this;
                this.loading = true

                const access_token = await jwt.getToken();

                axis.get('' + this.WebAPIURL + '/api/UserProfile/GetStripeAccounts',
                    {
                        headers: {
                            'Authorization': 'bearer ' + access_token,
                            'Content-Type': 'x-www-form-urlencode'
                        }
                    }
                )
                    .then(response => {
                        // console.log("Stripe response.data:: " + response.data);

                        self.loading = false
                        self.stripeAccoutns = response.data;
                        self.dataLoaded = true;
                    })
                    .catch(error => {
                        this.errorMessage = error.message;
                        self.dataLoaded = true;
                        // console.error("There was an error!", error);
                    });


            },

            addEditStripe: async function (e) {
                const access_token = await jwt.getToken();

                const payPalId = e.currentTarget.getAttribute('data-id');

                this.error = null
                var self = this;
                this.loading = true


                const params = new URLSearchParams();
                params.append('Id', payPalId);


                axis.get('' + this.WebAPIURL + '/api/UserProfile/GetStripeAccountDetails',
                    {
                        params: params,
                        headers: {
                            'Authorization': 'bearer ' + access_token,
                            'Content-Type': 'x-www-form-urlencode'
                        }
                    }
                )
                    .then(response => {
                        self.stripeAccountDetails = response.data;
                        self.stripeLiveAccountDetails = response.data;
                        self.$nextTick(() => {
                            self.$bvModal.show('stripeAccountModal')
                        })
                        self.loading = false
                    })
                    .catch(error => {
                        this.errorMessage = error.message;
                        // console.error("There was an error!", error);
                    });
            },

            closeStripeModal: async function () {
                this.$bvModal.hide('stripeAccountModal');
            },

            validateEmailAndConnect(mode) {
                this.$refs.emailValidator.validate().then(async (success) => {
                    if (success.valid) {
                        // Perform your action to connect with the provided email
                        const isEmailVerified = await this.VerifyStripeEmail(this.stripeAccountDetails.Email, this.stripeAccountDetails.Id);
                        if (!isEmailVerified) {
                            this.getStripeAccountKeys(mode);
                        }
                        else {
                            this.$swal(
                                "Error",
                                "Email Already Exists",
                                "error"
                            );
                        }
                    }
                });
            },

            VerifyStripeEmail: async function (email, Id) {
                const access_token = await jwt.getToken();

                return axis.get('' + this.WebAPIURL + '/api/UserProfile/VerifyStripeEmail?Email=' + email + '&Id=' + Id,
                    {
                        headers: {
                            'Authorization': 'bearer ' + access_token
                        }
                    }
                )
                    .then(response => {
                        return response.data;
                    })
                    .catch(error => {
                        this.errorMessage = error.message;
                        // console.error("There was an error!", error);
                    });
            },

            getStripeAccountKeys: async function (mode) {
                const access_token = await jwt.getToken();

                this.error = null
                var self = this;
                this.loading = true
                this.stripeMode = mode;

                var data = {
                    'Id': this.stripeAccountDetails.Id,
                    'PaymentMode': this.stripeMode,
                    'Email': this.stripeAccountDetails.Email,
                    'IsActive': this.stripeAccountDetails.IsActive
                };

                //const isEmailVerified = await this.VerifyStripeEmail(this.stripeAccountDetails.Email, this.stripeAccountDetails.Id);

                //if (!isEmailVerified) {
                axis.post('' + this.WebAPIURL + '/api/UserProfile/VerifySaveStripe',
                    data,
                    {
                        headers: {
                            'Authorization': 'bearer ' + access_token,
                            'Content-Type': 'application/json'
                        }
                    }
                )
                    .then(response => {

                        self.$nextTick(() => {
                            self.$bvModal.hide('stripeAccountModal')
                        })

                        if (response.data != "NotSaved") {
                            window.location.href = response.data;
                        }
                        self.loading = false;
                        // self.fetchStripeAccounts();
                       // self.stripeAccoutns = response.data;
                    })
                    .catch(error => {
                        this.errorMessage = error.message;
                        // console.error("There was an error!", error);
                    });
                //}
                //else {
                //    this.$swal(
                //        "Error",
                //        "Email is Already Exist",
                //        "error"
                //    );
                //}

            },

            verifyStripeAccount: async function () {
                this.error = null
                var self = this;
                this.loading = true
                const access_token = await jwt.getToken();

                const params = new URLSearchParams();
                params.append('scope', this.stripescope);
                params.append('state', this.stripeState);
                params.append('code', this.stripeCode);

                //stripeAccountId

                axis.get('' + this.WebAPIURL + '/api/UserProfile/VerifyStripe',
                    {
                        params: params,
                        headers: {
                            'Authorization': 'bearer ' + access_token,
                            'Content-Type': 'application/json'
                        }
                    }
                )
                    .then(response => {
                        self.loading = false
                        self.stripeAccountDetails = response.data;
                        self.$router.push('/paymentmethods');
                        self.tabActivated(1);
                        this.activeTab = "Stripe";
                        self.$nextTick(() => {
                            self.$bvModal.show('stripeAccountModal')
                        })

                    })
                    .catch(error => {
                        this.errorMessage = error.message;
                        // console.error("There was an error!", error);
                        self.$router.push('/paymentmethods');
                    });
            },

            SaveStripeAccount: async function () {
                this.error = null
                var self = this;
                const access_token = await jwt.getToken();

                this.$refs.stripeRules.validate().then(async (success) => {
                    if (success) {
                        const isEmailVerified = await this.VerifyStripeEmail(this.stripeAccountDetails.Email, this.stripeAccountDetails.Id);

                        if (!isEmailVerified) {
                            this.loading = true
                            var data = {
                                'Id': this.stripeAccountDetails.Id,
                                'Email': this.stripeAccountDetails.Email,
                                'AccessToken': this.stripeAccountDetails.AccessToken,
                                'Error': this.stripeAccountDetails.Error,
                                'ErrorDescription': this.stripeAccountDetails.ErrorDescription,
                                'LiveMode': this.stripeAccountDetails.LiveMode,
                                'RefreshToken': this.stripeAccountDetails.RefreshToken,
                                'Scope': this.stripeAccountDetails.Scope,
                                'StripeSecretKey': this.stripeAccountDetails.StripeSecretKey,
                                'StripePublishableKey': this.stripeAccountDetails.StripePublishableKey,
                                'StripeUserId': this.stripeAccountDetails.StripeUserId,
                                'TokenType': this.stripeAccountDetails.TokenType,
                                'IsActive': this.stripeAccountDetails.IsActive,
                                'ObjectJson': this.stripeAccountDetails.ObjectJson,
                                'RequestDate': this.stripeAccountDetails.RequestDate,
                                'RequestId': this.stripeAccountDetails.RequestId,
                                'ResponseJson': this.stripeAccountDetails.ResponseJson,
                                'State': this.stripeAccountDetails.State,
                                'MainUserId': this.stripeAccountDetails.MainUserId,
                                'RecCreatedBy': this.stripeAccountDetails.RecCreatedBy,
                                'PaymentMode': this.stripeMode,
                                'LiveAccessToken': this.stripeAccountDetails.LiveAccessToken,
                                'StripeLiveSecretKey': this.stripeAccountDetails.StripeLiveSecretKey,
                                'LiveError': this.stripeAccountDetails.LiveError,
                                'LiveErrorDescription': this.stripeAccountDetails.LiveErrorDescription,
                                'LiveRefreshToken': this.stripeAccountDetails.LiveRefreshToken,
                                'StripeLivePublishableKey': this.stripeAccountDetails.StripeLivePublishableKey,
                                'StripeLiveUserId': this.stripeAccountDetails.StripeLiveUserId,
                                'LiveObjectJson': this.stripeAccountDetails.LiveObjectJson,
                                'LiveRequestId': this.stripeAccountDetails.LiveRequestId,
                                'StripeLiveResponseJson': this.stripeAccountDetails.StripeLiveResponseJson,

                            };

                            axis.post('' + this.WebAPIURL + '/api/UserProfile/SaveStripeAccount',
                                data,
                                {
                                    headers: {
                                        'Authorization': 'bearer ' + access_token,
                                        'Content-Type': 'application/json'
                                    }
                                }
                            )
                                .then(response => {
                                    self.loading = false;
                                    self.$bvModal.hide('stripeAccountModal');

                                    self.fetchStripeAccounts();

                                    if (response.data == "Success") {
                                        if (this.stripeAccountDetails.Id == 0) {
                                            this.$swal(
                                                "Success",
                                                "",
                                                "success"
                                            );
                                        } else {
                                            this.$swal(
                                                "Success",
                                                "",
                                                "success"
                                            );
                                        }
                                    }
                                    else if (response.data == "NotSaved") {
                                        if (this.stripeAccountDetails.Id == 0) {
                                            this.$swal(
                                                "Error",
                                                "Stripe Account is not added. Try again",
                                                "error"
                                            );
                                        } else {
                                            this.$swal(
                                                "Error",
                                                "Stripe Account is not updated. Try again",
                                                "error"
                                            );
                                        }
                                    }
                                })
                                .catch(error => {
                                    this.errorMessage = error.message;
                                    self.loading = false;
                                    self.$bvModal.hide('stripeAccountModal');
                                });
                        }
                        else {
                            this.$swal(
                                "Error",
                                "Email is Already Exist",
                                "error"
                            );
                        }
                    }

                });
            },

            removeStripe: async function (e) {
                const stripeId = e.currentTarget.getAttribute('data-id');
                const access_token = await jwt.getToken();
                this.error = null;
                var self = this;
                this.$swal({
                    title: "Delete Stripe Account",
                    text: "Are you sure?",
                    /*icon: "warning",*/
                    showCancelButton: true,
                    confirmButtonText: "Delete",
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        self.loading = true

                        let formData = new FormData();

                        formData.append('Id', stripeId);


                        axis.post('' + self.WebAPIURL + '/api/UserProfile/DeleteStripeAccount',
                            formData,
                            {
                                headers: {
                                    'Authorization': 'bearer ' + access_token,
                                    'Content-Type': 'application/json'
                                }
                            }
                        )
                            .then(response => {
                                self.loading = false;
                                //if (response.data) {
                                //    this.$swal(
                                //        "Success",
                                //        "Stripe Account is deleted successfully",
                                //        "success"
                                //    );
                                //}
                                //else {
                                //    this.$swal(
                                //        "Error",
                                //        "Stripe Account cannot be deleted",
                                //        "error"
                                //    );
                                //}
                                self.fetchStripeAccounts();
                                //self.payPalAccoutns = response.data;
                            })
                            .catch(error => {
                                this.errorMessage = error.message;
                                console.error("There was an error!", error);
                            });
                    }
                });
            },

            closeAuthorizeModal() {
                this.$bvModal.hide("AuthorizeNetModal");
            },

            saveAuthorizeNetAccount: async function () {
                const access_token = await jwt.getToken();

                this.$refs.authorizeNetRules.validate().then((success) => {
                    if (success) {

                        this.error = null
                        var self = this;
                        this.loading = true


                        var data = {
                            'Id': this.authorizeNetAccountDetails.Id,
                            'IsActive': this.authorizeNetAccountDetails.IsActive,
                            'ApiLoginId': this.authorizeNetAccountDetails.ApiLoginId,
                            'AuthorizeNetTransactionKey': this.authorizeNetAccountDetails.AuthorizeNetTransactionKey,
                            'AccountName': this.authorizeNetAccountDetails.AccountName,
                            'LiveApiLoginId': this.authorizeNetAccountDetails.LiveApiLoginId,
                            'LiveAuthorizeNetTransactionKey': this.authorizeNetAccountDetails.LiveAuthorizeNetTransactionKey,
                        };

                        axis.post('' + this.WebAPIURL + '/api/UserProfile/VerifySaveAuthorizeNet',
                            data,
                            {
                                headers: {
                                    'Authorization': 'bearer ' + access_token,
                                    'Content-Type': 'application/json'
                                }
                            }
                        )
                            .then(response => {
                                self.$nextTick(() => {
                                    self.$bvModal.hide('AuthorizeNetModal')
                                })
                                self.loading = false
                                if (response.data == "Saved") {
                                    this.fetchAuthorizeNetAccounts();
                                    if (this.authorizeNetAccountDetails.Id == 0) {
                                        this.$swal(
                                            "Success",
                                            "",
                                            "success"
                                        );
                                    } else {
                                        this.$swal(
                                            "Success",
                                            "",
                                            "success"
                                        );
                                    }
                                } else {
                                    if (this.authorizeNetAccountDetails.Id == 0) {
                                        this.$swal(
                                            "Error",
                                            "Authorize Net Account is not added. Try again",
                                            "error"
                                        );
                                    } else {
                                        this.$swal(
                                            "Error",
                                            "Authorize Net Account is not updated. Try again",
                                            "error"
                                        );
                                    }
                                }
                                //self.payPalAccoutns = response.data;
                            })
                            .catch(error => {
                                this.errorMessage = error.message;
                                // console.error("There was an error!", error);
                            });
                    }
                });
            },

            addEditAuthorizeNet: async function (e) {

                const payPalId = e.currentTarget.getAttribute('data-id');

                this.error = null
                var self = this;
                this.loading = true
                const access_token = await jwt.getToken();

                const params = new URLSearchParams();
                params.append('Id', payPalId);


                axis.get('' + this.WebAPIURL + '/api/UserProfile/GetAuthorizeNetAccountDetails',
                    {
                        params: params,
                        headers: {
                            'Authorization': 'bearer ' + access_token,
                            'Content-Type': 'x-www-form-urlencode'
                        }
                    }
                )
                    .then(response => {
                        self.authorizeNetAccountDetails = response.data;
                        self.$nextTick(() => {
                            self.$bvModal.show('AuthorizeNetModal')
                        })
                        self.loading = false
                    })
                    .catch(error => {
                        this.errorMessage = error.message;
                        // console.error("There was an error!", error);
                    });
            },

            removeAuthorizeNet: async function (e) {

                const payPalId = e.currentTarget.getAttribute('data-id');

                const access_token = await jwt.getToken();
                this.error = null
                var self = this;
                this.$swal({
                    title: "Delete Authorize.Net Account",
                    text: "Are you sure?",
                    /*icon: "warning",*/
                    showCancelButton: true,
                    confirmButtonText: "Delete",
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {

                        self.loading = true
                        let formData = new FormData();
                        formData.append("Id", payPalId);
                        axis.post('' + self.WebAPIURL + '/api/UserProfile/DeleteAuthorizeNet', formData,
                            {
                                headers: {
                                    'Authorization': 'bearer ' + access_token,
                                    'Content-Type': 'application/json'
                                }
                            }
                        )
                            .then(response => {
                                self.loading = false;
                                //if (response.data) {
                                //    this.$swal(
                                //        "Success",
                                //        "Authorize Net Account is deleted successfully",
                                //        "success"
                                //    );
                                //}
                                //else {
                                //    this.$swal(
                                //        "Error",
                                //        "Authorize Net Account cannot be deleted",
                                //        "error"
                                //    );
                                //}

                                this.fetchAuthorizeNetAccounts();
                            })
                            .catch(error => {
                                this.errorMessage = error.message;
                                // console.error("There was an error!", error);
                            });
                    }
                });
            },

            fetchAuthorizeNetAccounts: async function () {
                this.error = null
                var self = this;
                this.loading = true

                const access_token = await jwt.getToken();

                axis.get('' + this.WebAPIURL + '/api/UserProfile/GetAuthorizeNetAccounts',
                    {
                        headers: {
                            'Authorization': 'bearer ' + access_token,
                            'Content-Type': 'x-www-form-urlencode'
                        }
                    }
                )
                    .then(response => {
                        // console.log("Authorize response.data:: " + response.data);
                        self.authorizeAccoutns = response.data;
                        self.loading = false
                    })
                    .catch(error => {
                        this.errorMessage = error.message;
                        // console.error("There was an error!", error);
                    });
            },

            fetchBrainTreeAccounts: async function () {
                this.error = null
                var self = this;
                this.loading = true
                const access_token = await jwt.getToken();

                axis.get('' + this.WebAPIURL + '/api/UserProfile/GetBrainTreeAccounts',
                    {
                        headers: {
                            'Authorization': 'bearer ' + access_token,
                            'Content-Type': 'x-www-form-urlencode'
                        }
                    }
                )
                    .then(response => {
                        console.log("BrainTree response.data:: " + response.data);
                        self.brainTreeAccoutns = response.data;
                        self.loading = false
                    })
                    .catch(error => {
                        this.errorMessage = error.message;
                        console.error("There was an error!", error);
                    });
            },
            addEditBrainTree: async function (e) {

                const payPalId = e.currentTarget.getAttribute('data-id');

                this.error = null
                var self = this;
                this.loading = true
                const access_token = await jwt.getToken();

                const params = new URLSearchParams();
                params.append('Id', payPalId);


                axis.get('' + this.WebAPIURL + '/api/UserProfile/GetBrainTreeeAccountDetails',
                    {
                        params: params,
                        headers: {
                            'Authorization': 'bearer ' + access_token,
                            'Content-Type': 'x-www-form-urlencode'
                        }
                    }
                )
                    .then(response => {
                        self.brainTreeAccountDetails = response.data;
                        self.$nextTick(() => {
                            self.$bvModal.show('BrainTreeModal')
                        })
                        self.loading = false
                    })
                    .catch(error => {
                        this.errorMessage = error.message;
                        console.error("There was an error!", error);
                    });
            },
            saveBrainTreeAccount: async function () {
                const access_token = await jwt.getToken();

                this.$refs.brainTreeRules.validate().then((success) => {
                    if (success) {

                        this.error = null
                        var self = this;
                        this.loading = true


                        var data = {
                            'Id': this.brainTreeAccountDetails.Id,
                            'MerchantId': this.brainTreeAccountDetails.MerchantId,
                            'MerchantAccountId': this.brainTreeAccountDetails.MerchantAccountId,
                            'PlanId': this.brainTreeAccountDetails.PlanId,
                            'PrivateKey': this.brainTreeAccountDetails.PrivateKey,
                            'PublicKey': this.brainTreeAccountDetails.PublicKey,
                            'AccountName': this.brainTreeAccountDetails.AccountName,

                        };

                        axis.post('' + this.WebAPIURL + '/api/UserProfile/VerifySaveBrainTree',
                            data,
                            {
                                headers: {
                                    'Authorization': 'bearer ' + access_token,
                                    'Content-Type': 'application/json'
                                }
                            }
                        )
                            .then(response => {
                                self.$nextTick(() => {
                                    self.$bvModal.hide('BrainTreeModal')
                                })
                                self.loading = false
                                if (response.data == "Saved") {
                                    this.fetchBrainTreeAccounts();
                                    if (this.brainTreeAccountDetails.Id == 0) {
                                        this.$swal(
                                            "Success",
                                            "Brain Tree Account is added successfully",
                                            "success"
                                        );
                                    } else {
                                        this.$swal(
                                            "Success",
                                            "Brain Tree Account is updated successfully",
                                            "success"
                                        );
                                    }
                                } else {
                                    if (this.brainTreeAccountDetails.Id == 0) {
                                        this.$swal(
                                            "Error",
                                            "Brain Tree Account is not added. Try again",
                                            "error"
                                        );
                                    } else {
                                        this.$swal(
                                            "Error",
                                            "Brain Tree Account is not updated. Try again",
                                            "error"
                                        );
                                    }
                                }
                                //self.payPalAccoutns = response.data;
                            })
                            .catch(error => {
                                this.errorMessage = error.message;
                                console.error("There was an error!", error);
                            });
                    }
                });
            },
            removeBrainTree: async function (e) {

                const payPalId = e.currentTarget.getAttribute('data-id');

                this.error = null
                var self = this;
                this.loading = true
                const access_token = await jwt.getToken();

                let formData = new FormData();

                formData.append("Id", payPalId);


                axis.post('' + this.WebAPIURL + '/api/UserProfile/DeleteBrainTree', formData,
                    {
                        headers: {
                            'Authorization': 'bearer ' + access_token,
                            'Content-Type': 'application/json'
                        }
                    }
                )
                    .then(response => {
                        self.brainTreeAccoutns = response.data;
                        self.loading = false
                    })
                    .catch(error => {
                        this.errorMessage = error.message;
                        console.error("There was an error!", error);
                    });
            },

        }
    }
</script>